import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteError } from 'react-router-dom';
import AnimatedComponent from '../Components/animated.component';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  return (
    <AnimatedComponent>
      <div className="column32">
        <h1 className='hero-title'>{error.status}</h1>
        <p className='sub-title'>
            {/* Sorry, an unexpected error has occurred.  */}
            {error.statusText || error.message}
        </p>
        <i className='primary-text'>
          {error.message || error.data}
          <br /><br />
          {error.error.stack || error.message}
        </i>
        <Link to={`/home`} className='primary-button'>Go to Home <FeatherIcon icon='arrow-up-right' size={22} /></Link>
      </div>
    </AnimatedComponent>
  );
}
