import React from 'react';
import FeatherIcon from 'feather-icons-react';
import AnimatedComponent from '../Components/animated.component';
import { NavLink } from 'react-router-dom';

const HomePage = () => {
    const formRef = React.useRef();
    const [user_email, setUserEmail] = React.useState('');
    const [user_name, setUserName] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [subject, setSubject] = React.useState('');

    const contactRef = React.useRef();
    const projectsRef = React.useRef();
    const experienceRef = React.useRef();
    const aboutref = React.useRef();

    const handleSubmit = async (ev) => {
        ev.preventDefault();

        setSubject("Sending...");

        // const client = new SMTPClient({
        //     host: "smtp.hostinger.com",
        //     port: 465,
        //     user: "info@allenchinodakufa.com",
        //     password: "G7b6208{",
        // });

        // const client_message = new Message({
        //     to: 'info@allenchinodakufa.com',
        //     from: user_email,
        //     Subject: `You have a new form submission from ${user_email}`,
        //     text: `Site
        //         Allen Chinodakufa Site

        //         Submitted content

        //         Name: ${user_name}

        //         Email: ${user_email}

        //         Message: ${message}`,
        // });


        // client.send(client_message, (err, msg) => {
        //     console.log(err || msg);

        //     if (msg) {
        //         SetIsSubmitted(true);
        //         SetIsError(true);
        //     }
        //     if (err) {
        //         SetIsSubmitted(true);
        //         SetIsError(true);
        //     }
        // });


        setSubject("Submit");
        return false;
    }
    return (
        <>
            {/*  Navigation  */}

            <header className="navbar">
                <ul className="navbar-menu">
                    <li>
                        <NavLink
                            onClick={() =>
                                aboutref.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start"
                                })
                            }>About</NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={() =>
                                experienceRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start"
                                })
                            }>Experience</NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={() =>
                                projectsRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start"
                                })
                            }>Projects</NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={() =>
                                contactRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start"
                                })
                            }>Contact Me</NavLink>
                    </li>
                </ul>
                <NavLink
                    onClick={() =>
                        contactRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start"
                        })
                    } className="primary-button">
                    Let's chat
                    <FeatherIcon icon="arrow-right" size={22} />
                </NavLink>
            </header>

            {/* Hero */}
            <AnimatedComponent transition={{ delay: 0.05 }} id="hero">
                <div className="flex-auto">
                    <div className="col6">
                        <div className="column16">
                            <h1 className="hero-title">I am Allen Chindakufa</h1>
                            <p className="primary-text">I'm, a passionate and detail-oriented software developer with a deep
                                commitment
                                to delivering high-quality, efficient solutions that align with your business goals.
                                Looking forward to connecting!
                            </p>
                            <div className="flex16">
                                <button onClick={(ev) => {
                                    ev.preventDefault();
                                    contactRef.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }} className="primary-button">
                                    Let's Chat
                                    <FeatherIcon icon="arrow-right" size={22} />
                                </button>
                                <a href="./Assets/Images/Docs/Allen CV.pdf" className="secondary-button" download>
                                    Download CV
                                    <FeatherIcon icon="download" size={22} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col6"></div>
                </div>
            </AnimatedComponent>

            {/* Favorites */}
            <AnimatedComponent transition={{ delay: 0.05 }} className="dark-bg" id="favorites">
                <div className="column64">
                    <div className="column16">
                        <h4 className="sub-title white-text">Favorite Design</h4>
                        <h1 className="title-text white-text">Some of my most favorite work</h1>
                        <div className="divider-light"></div>
                    </div>

                    <div className="flex32">
                        <div className="col6">
                            <div className="image-container">
                                <img src="./Assets/Images/Food.png" alt="" className="image-fluid" />
                            </div>
                        </div>
                        <div className="col6">
                            <div className="grid32">
                                <div className="col12">
                                    <div className="image-container">
                                        <img src="./Assets/Images/Faq.png" alt="" className="image-fluid" />
                                    </div>
                                </div>
                                <div className="col12">
                                    <div className="image-container">
                                        <img src="./Assets/Images/Home.png" alt="" className="image-fluid" />
                                    </div>
                                </div>
                                <div className="col12">
                                    <div className="image-container">
                                        <img src="./Assets/Images/Sign Up.png" alt="" className="image-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* About */}
            <AnimatedComponent transition={{ delay: 0.05 }} id="about" >
                <div className="card" ref={aboutref}>
                    <div className="column32">
                        <div className="column16">
                            <h4 className="sub-title">Start Here</h4>
                            <h1 className="title-text">About Me</h1>
                            <div className="divider"></div>
                        </div>

                        <p>I am a motivated software engineer with a solid understanding of programming languages and
                            frameworks. Frameworks like ReactJS, AngularJS, Flutter, and Python Django. I am passionate about
                            solving our modern day problems with technology. My approach ensures high-quality work and effective
                            use of time and resources. My communication abilities are excellent, allowing me to effectively
                            express complicated technical concepts to non-technical associates while keeping everyone on the
                            same page. I've successfully worked with cross-functional teams to achieve extraordinary results. My
                            curiosity and drive to study keep me up to date on the industry's latest technologies and trends.
                        </p>

                        <button onClick={(ev) => {
                            ev.preventDefault();
                            contactRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start"
                            })
                        }} className="primary-button">
                            Let's chat
                            <FeatherIcon icon="arrow-right" size={22} />
                        </button>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Experience */}
            <AnimatedComponent transition={{ delay: 0.05 }} className="dark-bg" id="experience">
                <div className="column64" ref={experienceRef}>
                    <div className="column16">
                        <h4 className="sub-title white-text">Experience</h4>
                        <h1 className="title-text white-text">Path to Innovation</h1>
                        <div className="divider-light"></div>
                    </div>

                    {/* Experience --> */}
                    <div className="timeline">
                        {/* 2019 */}
                        <div className="column48">
                            <h3 className="sub-title white-text">2019</h3>
                            <div className="timeline-content">
                                <h3 className="sub-title white-text">Web Developer</h3>
                                <ul className="List">
                                    <li>
                                        <p className="primary-text">I contributed to the development of a responsive and dynamic
                                            website
                                            that served both students and faculty.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">My work ensured that the website was visually
                                            appealing, functional, and user-friendly.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Additionally, I analyzed user feedback to continuously
                                            improve the website's performance and functionality.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 2020 */}
                        <div className="column48">
                            <h3 className="sub-title white-text">2020</h3>
                            <div className="timeline-content">
                                <h3 className="sub-title white-text">Freelance Web Developer</h3>
                                <ul className="List">
                                    <li>
                                        <p className="primary-text">I developed efficient and interactive websites using HTML, CSS,
                                            and JavaScript.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">I collaborated with designers and clients to achieve
                                            high-quality results and ensure client satisfaction.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Additionally, I budgeted project costs, calculated
                                            timelines, and administered tasks to meet deadlines effectively.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 2022 */}
                        <div className="column48">
                            <h3 className="sub-title white-text">2022</h3>
                            <div className="timeline-content">
                                <h3 className="sub-title white-text">Mobile Application Developer</h3>
                                <ul className="List">
                                    <li>
                                        <p className="primary-text">I used my app development skills to create innovative solutions
                                            and engaging experiences for the institution.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">By guiding team members, I enhanced our efficiency and achieved
                                            our goals effectively.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Additionally, I led a team of coders, fostering collaboration
                                            and communication to ensure success.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 2023 */}
                        <div className="column48">
                            <h3 className="sub-title white-text">2023</h3>
                            <div className="timeline-content">
                                <h3 className="sub-title white-text">Marketing / Sales</h3>
                                <ul className="List">
                                    <li>
                                        <p className="primary-text">Communicated with restaurants and hotels in Masvingo for sales
                                        </p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Managed payments for food and transport rentals, signed up new
                                            clients.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Effectively maintained consistent communication with multiple
                                            stakeholders to ensure smooth operations.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 2024 */}
                        <div className="column48">
                            <h3 className="sub-title white-text">2024 - Present</h3>
                            <div className="timeline-content">
                                <h3 className="sub-title white-text">Warehouse Associate</h3>
                                <ul className="List">
                                    <li>
                                        <p className="primary-text">Experience in Problem Solving, ICQA, PIT driving, DeStuff-It,
                                            Stowing, and Turret operations.</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Gained skills in quick learning, information retention, data
                                            management, and communication on Slack</p>
                                    </li>
                                    <li>
                                        <p className="primary-text">Demonstrated mindfulness in daily tasks, a strategic approach to
                                            workflow, tactical problem-solving abilities.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* skills */}
            <AnimatedComponent transition={{ delay: 0.05 }} id="skills">
                <div className="column32">
                    <div className="column16">
                        <h4 className="sub-title">Skills</h4>
                        <h1 className="title-text">Competencies and Qualifications</h1>
                        <div className="divider"></div>
                    </div>
                    <div className="flex32">
                        {/* Web --> */}
                        <div className="col4">
                            <div className="box">
                                <div className="column16">
                                    <h1 className="hero-title">1</h1>
                                    <p className="primary-text"><strong>Web Development</strong></p>
                                    <p className="primary-text">HTML & CSS, JavaScript, PHP, React, MySQL, Firebase</p>
                                    <button onClick={(ev) => {
                                        ev.preventDefault();
                                        contactRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }} className="secondary-button">
                                        View Certificate
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* App --> */}
                        <div className="col4">
                            <div className="box">
                                <div className="column16">
                                    <h1 className="hero-title">2</h1>
                                    <p className="primary-text"><strong>App Development</strong></p>
                                    <p className="primary-text">React Native, Flutter, Firebase, Mongo DB</p>
                                    <button onClick={(ev) => {
                                        ev.preventDefault();
                                        contactRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }} className="secondary-button">
                                        View Certificate
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Machine --> */}
                        <div className="col4">
                            <div className="box">
                                <div className="column16">
                                    <h1 className="hero-title">3</h1>
                                    <p className="primary-text"><strong>Machine Learning</strong></p>
                                    <p className="primary-text">AWS Machine Learning, Python, Jupiter Notebook</p>
                                    <button onClick={(ev) => {
                                        ev.preventDefault();
                                        contactRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }} className="secondary-button">
                                        View Certificate
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Projects */}
            <AnimatedComponent transition={{ delay: 0.05 }} className="dark-bg" id="projects">

                <div className="column64 stretch" ref={projectsRef}>
                    <div className="column16">
                        <h4 className="sub-title white-text">Projects</h4>
                        <h1 className="title-text white-text">What I have been working on</h1>
                        <div className="divider-light"></div>
                    </div>
                    <div className="column stretch">
                        {/* Zivakwawakabva */}
                        <div className="flex">
                            <div className="col6">
                                <div className="image-container">
                                    <img src="./Assets/Images/Zivakwawakabva.png" className="image-fluid" loading="lazy" />
                                </div>
                            </div>
                            <div className="col6">
                                <div className="column32 p32left">
                                    <span className="tag">Ui/Ux App Design</span>
                                    <div className="column16">
                                        <h4 className="sub-title white-text">Zivakwawakabva</h4>
                                        <p className="primary-text white-text">Zivakwakwabva is an innovative language learning app
                                            focusing on improving shona skills for people in diverse parts of the world. An
                                            elegant
                                            design met with seamless interactions to keep the user engaged and focus.</p>
                                    </div>
                                    <a href="https://zivakwawakabva.com/" className="primary-text white-text" target="_blank"
                                        rel="noreferrer">Visit Website
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Alimah International */}
                        <div className="flex flex-reverse">
                            <div className="col6">
                                <div className="image-container">
                                    <img src="./Assets/Images/Alimah International.png" className="image-fluid" loading="lazy" />
                                </div>
                            </div>
                            <div className="col6">
                                <div className="column32 p32right">
                                    <span className="tag">Web Design</span>
                                    <div className="column16">
                                        <h4 className="sub-title white-text">Alimah International</h4>
                                        <p className="primary-text white-text">AlimahInternational.org was designed through a
                                            collaborative brainstorming process aimed at creating a stylish and user-friendly
                                            platform that effectively communicates the organization's mission. I focused on
                                            achieving a balance between aesthetics and functionality.</p>
                                    </div>
                                    <a href="https://www.alimahinternational.org/" className="primary-text white-text"
                                        target="_blank" rel="noreferrer">Visit Website
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Kadosh Voice Of Glory International */}
                        <div className="flex">
                            <div className="col6">
                                <div className="image-container">
                                    <img src="./Assets/Images/Kadosh Glory.png" className="image-fluid" loading="lazy" />
                                </div>
                            </div>
                            <div className="col6">
                                <div className="column32 p32left">
                                    <span className="tag">Web Design</span>
                                    <div className="column16">
                                        <h4 className="sub-title white-text">Kadosh Voice Of Glory International</h4>
                                        <p className="primary-text white-text">The design of Kadosh Glory's website was a result of
                                            a
                                            detailed brainstorming process aimed at achieving a visually appealing,
                                            user-friendly
                                            experience that effectively communicates its message. I analyzed data on user
                                            behavior
                                            and preferences, ensuring that the site would be both functional and engaging.</p>
                                    </div>
                                    <a href="https://www.kadoshglory.org/" className="primary-text white-text" target="_blank"
                                        rel="noreferrer">Visit Website
                                        <FeatherIcon icon="arrow-up-right" size={22} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AnimatedComponent>

            {/* Contact */}
            <AnimatedComponent transition={{ delay: 0.05 }} id="contact" >
                <div className="card" ref={contactRef}>
                    <div className="flex32">
                        <div className="col5">
                            <div className="column-auto column32">
                                <div className="column16">
                                    <h4 className="sub-title">Contact Us</h4>
                                    <h1 className="title-text">Get In Touch</h1>
                                    <div className="divider"></div>
                                </div>
                                <div className="flex8">
                                    <a href="https://www.linkedin.com/in/allen-chinodakufa-a08b9b1b7/" className="icon-button"
                                        target="_blank" rel="noreferrer">
                                        <FeatherIcon icon='linkedin' size={22} />
                                    </a>
                                    <a href="mailto:info@allenchinodakufa.com" className="icon-button" target="_blank"
                                        rel="noreferrer">
                                        <FeatherIcon icon='mail' size={22} />
                                    </a>
                                    <a href="tel:+16363481868" className="icon-button" target="_blank" rel="noreferrer">
                                        <FeatherIcon icon='phone' size={22} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col7">
                            <form className="column32" ref={formRef} onSubmit={handleSubmit}>
                                <h2 className="sub-title">Let's Connect</h2>
                                <div className="column16">
                                    {/* Name */}
                                    <div className="column8">
                                        <label className="primary-text">Name:</label>
                                        <input className="form-control" placeholder="Enter your full name..." name="user_name" value={user_name} onChange={(ev) => {
                                            ev.preventDefault();
                                            setUserName(ev.target.value)
                                        }}
                                            required />
                                    </div>
                                    {/* Email */}
                                    <div className="column8">
                                        <label className="primary-text">Email:</label>
                                        <input className="form-control" placeholder="Enter your email..." name="user_email" value={user_email} onChange={(ev) => {
                                            ev.preventDefault();
                                            setUserEmail(ev.target.value)
                                        }}
                                            required />
                                    </div>
                                    {/* Subject */}
                                    <div className="column8">
                                        <label className="primary-text">Subject:</label>
                                        <input className="form-control" placeholder="Enter your subject..." required value={subject} onChange={(ev) => {
                                            ev.preventDefault();
                                            setSubject(ev.target.value)
                                        }} />
                                    </div>
                                    {/* Message */}
                                    <div className="column8">
                                        <label className="primary-text">Message:</label>
                                        <textarea className="form-control" placeholder="Enter your message..." name="message" value={message} onChange={(ev) => {
                                            ev.preventDefault();
                                            setMessage(ev.target.value)
                                        }}
                                            required ></textarea>

                                    </div>
                                </div>

                                <button type="submit" className="primary-button">
                                    Lets Talk
                                    <FeatherIcon icon="arrow-right" size={22} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div >
            </AnimatedComponent >

        </>
    )

}

export default HomePage;